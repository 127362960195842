body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  color: #333;
}

.qupid-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.timer {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  color: #555;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 48px;
  margin-bottom: 10px;
  color: rgb(242, 80, 104);
}

p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
  max-width: 80%;
}

.bold {
  font-weight: bold;
}

.download-button {
  display: inline-block;
  margin-bottom: 20px;
}

.download-button img {
  height: 50px;
}

.support-info {
  margin-top: 20px;
  font-size: 14px;
  margin-left: 6%;
  color: #555;
}

.support-info p {
  margin: 20px 0;
}

footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  text-align: center;
}

.footer-links {
  margin-bottom: 15px;
}

.footer-links a {
  color: #555;
  text-decoration: none;
  margin: 0 10px;
}

.social-icons {
  margin-top: 15px;
}

.social-icons svg {
  width: 24px;
  height: 24px;
  margin: 0 15px;
  color: #000;
}

.social-icons a {
  color: #000;
  text-decoration: none;
}